    import React from 'react';

import { LabelSeries, MarkSeries, Sunburst, XYPlot} from 'react-vis';
import D3FlareData from './data.json';


const LABEL_STYLE = {
    fontSize: '16px',
    textAnchor: 'middle',
    color: 'red',
    backgroundColor: 'white'
};

/**
 * Recursively work backwards from highlighted node to find path of valud nodes
 * @param {Object} node - the current node being considered
 * @returns {Array} an array of strings describing the key route to the current node
 */
function getKeyPath(node) {
    if (!node.parent) {
        return [''];
    }

    return [(node.data && node.data.name) || node.name].concat(
        getKeyPath(node.parent)
    );
}

/**
 * Recursively modify data depending on whether or not each cell has been selected by the hover/highlight
 * @param {Object} data - the current node being considered
 * @param {Object|Boolean} keyPath - a map of keys that are in the highlight path
 * if this is false then all nodes are marked as selected
 * @returns {Object} Updated tree structure
 */
function updateData(data, keyPath) {
    if (data.children) {
        data.children.map(child => updateData(child, keyPath));
    }
    // add a fill to all the uncolored cells
    if (!data.hex) {
        data.style = {
            fill: "grey" // better to use a library with a theme
        };
    }
    data.style = {
        ...data.style,
        fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1
    };

    return data;
}
const decoratedData = updateData(D3FlareData, false);


export default class SunbirstExplains extends React.Component {
    state = {
        pathValue: false,
        data: decoratedData,
        finalValue: 'SUNBURST',
        clicked: false
    };

    render() {

        const {clicked, data, finalValue, pathValue} = this.state;
        // const data = [];

        return (
            <div className="basic-sunburst-example-wrapper" >
                <div>
                    <i>{clicked ? 'click to unlock selection' : 'click to lock selection'}</i>
                </div>
                <Sunburst
                    animation
                    className="basic-sunburst-example"
                    hideRootNode
                    onValueMouseOver={node => {
                        if (clicked) {
                            return;
                        }
                        const path = getKeyPath(node).reverse();
                        const pathAsMap = path.reduce((res, row) => {
                            res[row] = true;
                            return res;
                        }, {});
                        this.setState({
                            finalValue: path[path.length - 1],
                            pathValue: path.join(' | '),
                            data: updateData(decoratedData, pathAsMap)
                        });
                    }}
                    onValueMouseOut={() =>
                        clicked
                            ? () => {}
                            : this.setState({
                                pathValue: false,
                                finalValue: false,
                                data: updateData(decoratedData, false)
                            })
                    }
                    onValueClick={() => this.setState({clicked: !clicked})}
                    style={{
                        stroke: '#ddd',
                        strokeOpacity: 0.3,
                        strokeWidth: '0.5'
                    }}
                    colorType="literal"
                    getSize={d => d.value}
                    getColor={d => d.hex}
                    data={data}
                    height={150}
                    width={200}
                >
                    {finalValue && (
                        <LabelSeries
                            data={[{x: 0, y: 0, label: finalValue, style: {color:'white'}}]}

                            // data={[{x: 0, y: 0, label: finalValue, style: LABEL_STYLE}]}
                        />
                    )}
                </Sunburst>
                <div style={{color: "white"}}>{pathValue}</div>
                {/*// className="basic-sunburst-example-path-name"*/}
            </div>
        );
    }




}

