import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scene from "../components/scenes/scene";
import ProfilePic from "../components/cropped-profile"

import LabelVis from "../components/graphs/LabelVis";
import SunbirstExplains from "../components/graphs/SunbirstExplains";

import LinkHintSankeyExample from '../components/graphs/sankey';

import GameSwitch from "../components/games/GameSwitch";
import GameSwirl from "../components/games/GameSwirl";

import CookieConsent from "react-cookie-consent";

// import ekb from '../images/draw-ekb-dark.png'
// import handsquare from "../images/draw-hand-dark-square.png"



const IndexPage = () => (
    <Layout>
        <script data-goatcounter="https://metrics.goatcounter.com/count"
                async src="//gc.zgo.at/count.js"></script>
        <SEO title="Pavel" />
        <br />
        <br />

        <GameSwitch />

        <div style={{
            display:`flex`,
            flexDirection:`row`,
            flexWrap:`wrap`,
            justifyContent: `space-between`,
            alignItems:`stretch`,
            alignContent: `space-between`
              }}
        >
            <span>
        <h1 style={{margin: `0 auto`, textAlign:`center`, padding:`0`}}>
            Here I structure
        </h1>
        <h1 style={{margin: `0 auto`, padding:`0`}} className={'gradient'}>
            things
        </h1>
            </span>

            {/*<span>*/}
            {/*    <Scene2 style={{width:`500px`, height:`auto`}}/>*/}
            {/*</span>*/}

            {/*<img*/}
            {/*    style={{width: `250px`, height: `auto`, color: `transparent`,*/}
            {/*WebkitBoxReflect: `below 4px linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1))`}}*/}
            {/*     src={handsquare}*/}
            {/*     alt={'hello'}*/}
            {/*/>*/}


            {/*<img*/}
            {/*    style={{width: `250px`, height: `auto`, margin: `25px`, color: `transparent`,*/}
            {/*WebkitBoxReflect: `below 4px linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1))`}}*/}
            {/*     src={ekb}*/}
            {/*     alt={''}*/}
            {/*/>*/}

        </div>

        <br />
        <br />
        <p>
            The page with a little information about me.<br/>
            Here is my <Link to="/resume/">Resume</Link>.<br />
            <p/>
            Some of my projects are <Link to="/projects/">in the list</Link>.
            <br/>
            The list is not full, here is my <a href="mailto:pavel.popov@mail.polimi.it">email</a>, if you have any
            question.
            <br />
            <br />
            This Website is a playground. I test front-end things here.
            <br />
            I designed and built it myself using <a href="https://reactjs.org/"
                                                    target="_blank"
                                                    rel="noopener noreferrer canonical">React</a>.
            <br />
            If you need, here is a <a href="/projects/2021-04-01-this-website/" target="_blank"
                                      rel="noopener noreferrer canonical">
            tech stack</a> of the project.
            <br />
            <br />
            <br />
            <div style={{ maxWidth: `300px`, margin: `0 auto`,
                OObjectFit: 'cover',
                OObjectPosition: 'center center',
                opacity: '1',
                transition: 'opacity 700ms ease 0s'
            }}>
                <ProfilePic />
            </div>
            {/*<div style={{ maxWidth: `1000px`, margin: `0 auto` }}>*/}
            {/*    <Scene />*/}
            {/*</div>*/}
            <br />
            <div style={{ margin: `0 auto` }}>
                <LinkHintSankeyExample />
            </div>
                {/*<SceneButton />*/}
                <h2>Why I wrote it</h2>
            <p>
                The reason of why I am writing this article and you, hopefully, reading it is simple. I want to have a
                reasonable short text explaining who am I, what I do for life and how I become
            </p>
            <h3>Beginning</h3>
            <p>In late 2013, one of my friends showed me a thing he called "spoofing" and a little program he created for
            getting some data. We were both 16 and I thought the guy is doing some magic.
            Thankfully, I have been a student of one of the best school in the city focused on math and physics. Moreover,
                my track was in math, so since 12 yo we were involved in different sort of programming. Unfortunately, things like
                Haskell is a bit difficult for small kids, so, no idea why we were taught this, but for many of my friends it was just a
                routine that used to give a sensing of cleverness.
            </p>
            <p>When you are young and you study at a physical school, you are probably  tired of physics. Same with math. With everything
                So, sometimes, we were completely done about tech and scientific stuff. Most of us are now lawyers, social workers, analysts.
                But this background we got in school definitely helped us in any of our beginnings.
            </p>

            <br />
            <br />
            <h3>How I define myself</h3>
                <p>
                    I work with systems. I used to work in its design, analysis and restructuring. During my bachelor,
                    I've been curious about theory of complexity lectures given to us at St.Petersburg Polytechnic University.
                    On these classes, people, who claimed to be founders of the Soviet cybernetics described to us attractors and
                    principles of complexity like emergence, bifurcations, dynamics. Knowing something from the school about systems,
                    I started to look where it can be applied commercially. I shortly found that it can be quite in use in the field,
                     where I already have been working at the moment - in architecture. More precisely - in urban studies.
                    Theory of systems is crucial for understanding cities and people living there. There are many books I would recommend
                    to read on the topic. There are some "LIST". Somehow, I met people working on the software called AnyLogic.
                    It is a tool allowing to create and test systems. I started to work with it just for fun creating simple models.
                    There are three types of systems modelling:
                    <ol>
                        <li>System Dynamics</li>
                        <li>Process modelling</li>
                        <li>Agent-based modelling</li>
                    </ol>
                    Having some knowledge in OOP, I decided to focus on the last one. Apparently, it was denoted as the most difficult,
                    but most informative (being also most computationally expensive). So, better for me.
                    Process modelling seemed clear and easy, so I skipped it (and met in after 1 year at the final semester of Bachelor)

                    Actually, after 2 months of working in this software and also trying to work with the same logic in some Grasshopper plugins
                     (PedSim), I got a proposal from my Portuguese friends to apply it for their architectural projects.
                    I agreed and set off to Lisbon. In their office near the Olympic Center I was working on already
                    created models of architectural environments checking the traffic and pedestrian flows. Parallel to that,
                    I tried my skills of generative design helping architects to make many solutions using Grasshopper.

                    I finished my work in a while because I had to continue education. Most important, I have already been
                    selected for an exchange year at TU Munich. So, in October 2018 I set off to Germany. There, I wanted to continue
                    working in system modelling not forgetting about general programming skills. The problem was in the fact that
                    on the last year I had to take most of the 'management courses'. It was necessary, so I took some.
                    Finally, at TU Munich my study plan looked like that:
                    <ul>
                        <li>Project Management</li>
                        <li>Performance Based Design (basically lvluped Grasshopper course)</li>
                        <li>Robotics and Design (programming, hardware and design, looked cool)</li>
                        <li>Modeling, Optimization and Simulation (AnyLogic applied to many spheres)</li>
                        <li>Transport planning (somehow included things like Dijkstra, A* and similar algorithms, TSP problems,
                        knapsack problems and things like that)</li>
                    </ul>
                    Need to mention that all courses at TUM where incredibly useful and cool. It has not been like this in any other Uni I've been to.

                    <br />
                    However, the coolest thing was to instantly find a job in Bayern. Studio Ossenberg-Engels, which is working on
                     usability of spaces, called me to work with Grasshopper. Their team was surprised when I showed them capabilities of agent-based modelling,
                    so they proposed several test project. I have to mention, that some of the customers were Siemens and BMW. After a while,
                    we decided to make a commercial project for an architectural re-planning of the research center of BMW.
                    I received all layouts from HENN, and most important, for my analysis I got some data collected by TNS Kantar and BMW itself.
                    Having all I need, I created a cool model which showed the problems in created design of spaces, so it can be avoided before this design has been
                    finally approved.
                    Afterward, I received a permit to use this project for my Bachelor Thesis.
                    <br />
                </p>

            <br />
            <br />
            {/*<div style={{textAlign:'center'}}>*/}
            <h2>About</h2>

            I usually work with generative design and simulation modeling,<br/>
            develop software with Python an JS,<br/>
            try to bring 3D into browser and mixed reality<br />
            and sometimes consult architects and creators.
            <br />
            <br />
            {/*Physically I can be <Link to="/geoarchive/">somewhere</Link> in Germany, Italy or Russia. If you are near,*/}
            {/*use  <a href="https://t.me/userpav" target="_blank" rel="noopener noreferrer">Telegram</a> to to reach me quickly.*/}
            {/*<br />*/}
            {/*Contact me by <a href="mailto:pavel.popov@mail.polimi.it">email</a> if you have an interesting project*/}
            {/*or just to say hi.*/}
            {/*or if you can help to finish OpenCV plugin*/}
            {/*for <a*/}
            {/*href="https://www.food4rhino.com/"*/}
            {/*target="_blank" rel="noopener noreferrer"> Food4Rhino</a>.*/}

            {/*</div>*/}
            <br />
            <br />
            <div style={{
                display:`flex`,
                flexDirection:`row`,
                flexWrap:`wrap`,
                justifyContent: `space-between`,
                alignItems:`stretch`,
                alignContent: `space-between`
            }}
            >

                <div>
                    <h1 className={'gradient-blue-pink'}>Tech</h1>
                    <p>This part explains what is going on with the technical experience</p>
                    <LabelVis />

                </div>

                <div>

                    <h1 className={'gradient-pink-blue'}>Management</h1>
                    <p>Here is an essential part about the experience in management and consulting</p>
                    <SunbirstExplains />

                </div>
            </div>

            <br />
        </p>
        <br />
        <div style={{ maxWidth: `1000px`, margin: `0 auto` }}>
            <Scene />
        </div>
        <CookieConsent
            debug={false}
            style={{textAlign: `center`, font: `Helvetica Neue`, fontFamily:`Helvetica Neue, serif` }}
            buttonText="Anyway"
            buttonStyle={{background: `#2626DB`,
                font: `Helvetica Neue`,
                color: `#fff`,
                fontFamily:`Helvetica Neue, serif`,
                }}
        >
            Yes, Cookies.
        </CookieConsent>
    </Layout>


)

export default IndexPage