import React from 'react';

import {XYPlot, XAxis, YAxis, MarkSeries, LabelSeries} from 'react-vis';


const LABEL_STYLE = {
    fontSize: '48px',
    textAnchor: 'middle',
    color: '#fafafa'
};


export default class LabelVis extends React.Component {


    render() {
        const data = [
            {
                x: 0,
                y: 7,
                label: 'Pavel',
                size: 50,
                style: {fontSize: 50, color: `white`},
                rotation: 45,

            },
            {x: 6, y: 4, label: 'Paul', size: 10, style: {fontSize: 21, backgroundColoe: "white"},},
            {x: 5, y: 10, label: 'Paolo', size: 6, style: {fontSize: 50, backgroundColoe: "white"},},
            {x: 3.5, y: 7, label: 'Pavli', size: 12, rotation: 45},
            {x: 1, y: 11, label: 'Pav', size: 4},
            {
                x: 6,
                y: 10,
                label: 'Pasha',
                size: 1,
                style: {fontSize: 10, backgroundColoe: "white"},
                rotation: 45,

            }
        ];

        return (
            <div style={{backgroundColor:"rgba(100,100,100,0.5)", borderRadius: "80px", color: "red"}}>

                <XYPlot yDomain={[-1, 22]} xDomain={[-1, 7]} width={450} height={300} style={{color:'white'}} >
                    {/*<XAxis />*/}
                    {/*<YAxis />*/}
                    <MarkSeries
                        className="mark-series-example"
                        strokeWidth={20}
                        sizeRange={[5, 15]}
                        data={data}
                        color={"red"}
                    />
                    <LabelSeries animation data={data} style={{color:'green'}}/>
                </XYPlot>
            </div>
        );
    }
}