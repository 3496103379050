
let SwitchSets = [
    {
        name: "square",
        switchCount: 100
    },
    {
        name: "wave",
        switchCount: 100
    },
    {
        name: "triangle",
        switchCount: 100
    },
    {
        name: "equals",
        switchCount: 100
    },
    {
        name: "circle",
        switchCount: 100
    }
]

export default SwitchSets;
